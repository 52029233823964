body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.bgcolor{
  background: #2B3C73;
  
  
  }
  .text-greys-900{
    color:#444346;
  }
  .hline{


height: 0px;
left: calc(50% - 268px/2 + 171px);


border: 1px solid rgba(43, 60, 115, 0.15);
transform: rotate(180deg);
  }

  .vline{
    border: 1px solid rgba(43, 60, 115, 0.15);
    transform: rotate(90deg);
    width: 100%;
    height: 1px; /* Adjust this value if needed */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
  }
  .sideimage{
    position: absolute;
    width: 50%;
    right: 0;
    height: 53%;
  }
.headingtext{
color: #2B3C73;

}
.textcolor{
  color: #F0F0F0;
  
  }
.imagecenter{
  position: absolute;
    width: 37%;
    height: auto;
    margin-left: -4%;
    margin-top: -6%;
}
.w-half{
  margin-left: 23%;   
  width:50%
}
.imagecenter2{
  position: absolute;
    width: 100%;
    height: auto;
    margin-left: -6%;
    margin-top: -18%;
}
.lowcard{
  margin-top:20%;
}
.bg-whitex{
  border-radius: 19px;
  background: rgba(255, 255, 255, 0.69);
}
.bgradial{
  background: radial-gradient(143.34% 142.28% at 48.18% 14.41%, #EAF5F9 35.67%, #669AB9 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
}
.faqbg {
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f2f9fb;
  margin-bottom: 1rem; /* Adjust for spacing between items */
  padding: 1rem; /* Add padding inside the FAQ item */
}

.faqtext {
  color: #444346;
}

.bgmodal {
  border: 1px solid #F8F6F6;
  background: #F8F6F6;
}

.faqfullbg {
  background: radial-gradient(143.34% 142.28% at 48.18% 14.41%, #EAF5F9 35.67%, #669AB9 100%);
}

.faq-answer {
  overflow: hidden;
  transition: max-height 0.3s ease;
  max-height: 0;
}

.faq-answer.open {
  max-height: 1000px; /* Adjust based on your content */
}

@media (min-width: 640px) {
  .faq-columns {
    display: flex;
    justify-content: space-between;
  }

  .faq-column {
    width: 48%; /* Adjust width as needed */
  }
}

@media (max-width: 639px) {
  .faq-column {
    width: 100%;
    padding-left: 1rem; /* Add padding to the left side */
    padding-right: 1rem; /* Add padding to the right side */
  }
}

.container {
  overflow: hidden;
}

.containerStyle{
  display: flex;
  justify-content: center;
  align-items : center;
  height: 100vh; 
}

.bg-light{
  background: #EAF5F9
}

.whyleft{
  margin-left: 72px;
    margin-right: 102px;
    margin-top: -16px;
}
.whyright{
  margin-right: 49px;
  margin-left: 103px;
  margin-top: -84px;
}
.testbg{
  border:1px solid #d4d7df;
  border-radius:4px;
  background: #f6f6f6;
}
.bright{
  border-right: 1px solid #2B3C7326;
  padding-bottom: 4%
  
}

.hlinepblm{
  border-right: 1px solid  #2B3C7326;
   
      padding-bottom: 0%
}
.brights{
   padding-bottom: 4%;
  
}
.hf{
  padding-top: 6%;
  padding-bottom: 6%;


}
.hf1{
  padding-top: 6%;
  padding-bottom: 0%;
margin-left:25%;

}
.hf11{
  padding-top: 0%;
  padding-bottom: 0%;
  margin-left: 5%;
  margin-bottom: 0%;
}

.ml123{
  margin-left: 105px;
}
.text-xl1{
  font-size:1.25rem;
}
.hline1{
  position: fixed;
  top: 250px;
  width: 300px;
  left: 179px;
  margin-bottom: 17px;
}
.ml50{
  margin-left: 49%;
}
.h64{
  height: 75px;
}
.m75{
  margin-right: 1.75rem;
  margin-left: 1.75rem;
}
.mb35{
  margin-top:3.5rem;
  margin-bottom: 3.5rem;
}  
.mb15{
  margin-top:2.5rem;
  margin-bottom: 1.5rem;
}   
.lineorg{
  margin-left: 6%;
  margin-top: -9px;
  margin-bottom: 6px;
}
.ml10{
  margin-left: 8rem;
  margin-top: 5%;
}
.pblsection{
  margin-left: 9.5rem;
  margin-right: 7.5rem;
}

.divide-blue-700 > :not([hidden]) ~ :not([hidden]) {
  border-bottom:1px solid rgba(240, 240, 240, 0.1)  ! important
}
.border-b{
 border-bottom: 0.3px solid  rgba(68, 67, 70, 1);
}
.textspexc{
  color: rgba(68, 67, 70, 1);

}

.ml-20s{
  margin-left: 25rem;
  margin-top: 5rem;
}

.hw20{
  width: 7rem;
  height: 7rem;
}
.bgback{
  background:rgba(234, 245, 249, 1);

}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  max-width: 80%;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  margin-top: 10px;
}


.marquee {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
}

.marquee-content {
  display: inline-block;
  animation: marquee 10s linear infinite;
}

@keyframes marquee {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}
.max-w-xs {
  max-width: 17rem;
}
.borderbg{
  border-bottom:1px solid rgba(240, 240, 240, 0.1)  ! important 
}
@tailwind base;
@tailwind components;
@tailwind utilities;



   @font-face {
    font-family: 'Diodrum';
    src: url('assets/font/Diodrum-Medium.woff2') format('woff2'),
         url('assets/font/Diodrum-Medium.woff') format('woff'),
         url('assets/font/Diodrum-Medium.ttf') format('truetype');
    font-weight: normal;
  }
  
  @font-face {
    font-family: 'Diodrum';
    src: url('assets/font/Diodrum-Semibold.woff2') format('woff2'),
         url('assets/font/Diodrum-Semibold.woff') format('woff'),
         url('assets/font/Diodrum-Semibold.ttf') format('truetype');
    font-weight: bold;
  }  
  * {font-family: Diodrum;}

  .loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  